import Action from "../images/RJNWaction.jpeg";

const Contacts = () => {
  return (
    <div id="contact">
      <section className="contacts-main">
        <div className="contacts-splash-section">
          <div className="contacts-img-container">
            <img src={Action} alt="close up of roof interior" />
          </div>
        </div>
        <div className="contacts-info-section">
          <h2>Get in touch</h2>
          <div className="contacts-details">
            <a
              className="contacts-details-link"
              href="mailto:info@roofingandjoinery.com"
              target="blank"
            >
              <p>info@roofingandjoinery.com</p>
            </a>
            <a className="contacts-details-link" href="tel:01617062540">
              <h4>0161 706 2540</h4>
            </a>
          </div>
        </div>
      </section>
      <div className="contacts-spacer-container">
        <div className="contacts-spacer-left"></div>
        <div className="contacts-spacer-right"></div>
      </div>
    </div>
  );
};

export default Contacts;
