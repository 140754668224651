import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade } from "swiper";

import Facia1 from "../images/facia/RJNWfacia1.jpeg";
import Facia2 from "../images/facia/RJNWfacia2.jpeg";
import Facia3 from "../images/facia/RJNWfacia3.jpeg";

import ServiceNav from "./ServiceNav.jsx";

const Facia = () => {
  return (
    <div>
      <ServiceNav />
      <section className="service-main">
        <div className="service-upper-section">
          <div className="service-header-section">
            <div className="service-header-container">
              <div className="service-header-p">
                <p>Our Services</p>
              </div>
              <div className="service-header-h1">
                <h1>FACIA AND SOFFITS</h1>
              </div>
            </div>
          </div>
          <div className="service-upper-space">
            <h4>
              CALL US NOW
              <br /> 0161 706 2540
            </h4>
          </div>
        </div>
        <div className="service-lower-section">
          <div className="service-carousel-section">
            <div className="service-img-container">
              <Swiper
                spaceBetween={30}
                effect={"fade"}
                pagination={{
                  clickable: true,
                }}
                modules={[EffectFade, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div
                    className="service-slide-img"
                    style={{
                      backgroundImage: `url(${Facia1})`,
                      backgroundPosition: "90% 20%",
                    }}
                  >
                    <div className="service-slide-info-container">
                      <div className="title">
                        <h1>1</h1>
                      </div>
                      <div className="subtitle">
                        <h4>Design</h4>
                      </div>
                      <div className="text">
                        <p>
                          Our facia and soffit services are designed to provide
                          our clients with high-quality solutions that will help
                          protect their homes against the elements.
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="service-slide-img"
                    style={{
                      backgroundImage: `url(${Facia2})`,
                    }}
                  >
                    <div className="service-slide-info-container">
                      <div className="title">
                        <h1>2</h1>
                      </div>
                      <div className="subtitle">
                        <h4>Materials</h4>
                      </div>
                      <div className="text">
                        <p>
                          We use only the best materials and techniques to
                          ensure that your home is well-protected and looks
                          great.
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="service-slide-img"
                    style={{
                      backgroundImage: `url(${Facia3})`,
                    }}
                  >
                    <div className="title">
                      <h1>3</h1>
                    </div>
                    <div className="subtitle">
                      <h4>Experience</h4>
                    </div>
                    <div className="text">
                      <p>
                        Our skilled team of tradespeople has extensive
                        experience in this area and will work with you to ensure
                        that your project is completed to your satisfaction.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="service-info-section">
            <div className="service-info-upper-section">
              <h3>20 YEARS OF EXPERTISE</h3>
              <button className="service-btn cta">
                <a className="call-btn-text" href="tel:01617062540">
                  CALL US NOW
                </a>
              </button>
            </div>
            <div className="service-info-lower-section">
              <div className="service-info-top">
                <p>
                  At Roofing and Joinery Northwest Ltd, we offer free,
                  non-obligatory quotes and estimates.
                </p>
              </div>
              <div className="service-info-bottom">
                <p>
                  Our surveys come with a detailed description of our findings,
                  so that all potential clients are fully aware of what work is
                  required.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Facia;
