import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination } from "swiper";

import Con1 from "../images/conversions/RJNWcon1.jpeg";
import Con2 from "../images/conversions/RJNWcon2.jpeg";
import Con3 from "../images/conversions/RJNWcon3.jpeg";

import ServiceNav from "./ServiceNav.jsx";

const Conversions = () => {
  return (
    <div>
      <ServiceNav />
      <section className="service-main">
        <div className="service-upper-section">
          <div className="service-header-section">
            <div className="service-header-container">
              <div className="service-header-p">
                <p>Our Services</p>
              </div>
              <div className="service-header-h1">
                <h1>CONVERSIONS</h1>
              </div>
            </div>
          </div>
          <div className="service-upper-space">
            <h4>
              CALL US NOW
              <br /> 0161 706 2540
            </h4>
          </div>
        </div>
        <div className="service-lower-section">
          <div className="service-carousel-section">
            <div className="service-img-container">
              <Swiper
                spaceBetween={30}
                effect={"fade"}
                pagination={{
                  clickable: true,
                }}
                modules={[EffectFade, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div
                    className="service-slide-img"
                    style={{
                      backgroundImage: `url(${Con1})`,
                    }}
                  >
                    <div className="title">
                      <h1>1</h1>
                    </div>
                    <div className="subtitle">
                      <h4>Design</h4>
                    </div>
                    <div className="text">
                      <p>
                        We will work with you to design a dorma that meets your
                        needs and budget, and our skilled team of tradespeople
                        will ensure that the project is completed to the highest
                        standards.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="service-slide-img"
                    style={{
                      backgroundImage: `url(${Con2})`,
                    }}
                  >
                    <div className="title">
                      <h1>2</h1>
                    </div>
                    <div className="subtitle">
                      <h4>Experience</h4>
                    </div>
                    <div className="text">
                      <p>
                        At Roofing and Joinery Northwest Ltd, we have extensive
                        experience in this area and can help you create a
                        stunning new space in your home.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="service-slide-img"
                    style={{
                      backgroundImage: `url(${Con3})`,
                      backgroundPosition: "30% 30%",
                    }}
                  >
                    <div className="title">
                      <h1>3</h1>
                    </div>
                    <div className="subtitle">
                      <h4>Value</h4>
                    </div>
                    <div className="text">
                      <p>
                        Dorma conversions are an excellent way to increase the
                        value and functionality of your home.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="service-info-section">
            <div className="service-info-upper-section">
              <h3>20 YEARS OF EXPERTISE</h3>
              <button className="service-btn cta">
                <a className="call-btn-text" href="tel:01617062540">
                  CALL US NOW
                </a>
              </button>
            </div>
            <div className="service-info-lower-section">
              <div className="service-info-top">
                <p>
                  At Roofing and Joinery Northwest Ltd, we offer free,
                  non-obligatory quotes and estimates.
                </p>
              </div>
              <div className="service-info-bottom">
                <p>
                  Our surveys come with a detailed description of our findings,
                  so that all potential clients are fully aware of what work is
                  required.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Conversions;
