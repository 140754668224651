import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination } from "swiper";

import Reroof1 from "../images/reroof/RJNWreroof1.jpeg";
import Reroof2 from "../images/reroof/RJNWreroof2.jpeg";
import Reroof3 from "../images/reroof/RJNWreroof3.jpeg";

import ServiceNav from "./ServiceNav.jsx";

const ReRoof = () => {
  return (
    <div>
      <ServiceNav />
      <section className="service-main">
        <div className="service-upper-section">
          <div className="service-header-section">
            <div className="service-header-container">
              <div className="service-header-p">
                <p>Our Services</p>
              </div>
              <div className="service-header-h1">
                <h1>RE-ROOFS</h1>
              </div>
            </div>
          </div>
          <div className="service-upper-space">
            <h4>
              CALL US NOW
              <br /> 0161 706 2540
            </h4>
          </div>
        </div>
        <div className="service-lower-section">
          <div className="service-carousel-section">
            <div className="service-img-container">
              <Swiper
                spaceBetween={30}
                effect={"fade"}
                pagination={{
                  clickable: true,
                }}
                modules={[EffectFade, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div
                    className="service-slide-img"
                    style={{
                      backgroundImage: `url(${Reroof1})`,
                      backgroundPosition: "90% 30%",
                    }}
                  >
                    <div className="title">
                      <h1>1</h1>
                    </div>
                    <div className="subtitle">
                      <h4>Design</h4>
                    </div>
                    <div className="text">
                      <p>
                        We will work with you to identify the best materials and
                        techniques for your project, ensuring that your new roof
                        provides you with the protection and aesthetics that you
                        need.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="service-slide-img"
                    style={{
                      backgroundImage: `url(${Reroof2})`,
                    }}
                  >
                    <div className="title">
                      <h1>2</h1>
                    </div>
                    <div className="subtitle">
                      <h4>Versatility</h4>
                    </div>
                    <div className="text">
                      <p>
                        Our team of experts is highly skilled in all types of
                        roofing materials, including traditional slating,
                        concrete and clay tiling, and flat roofing.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="service-slide-img"
                    style={{
                      backgroundImage: `url(${Reroof3})`,
                      backgroundPosition: "50% 50%",
                    }}
                  >
                    <div className="title">
                      <h1>3</h1>
                    </div>
                    <div className="subtitle">
                      <h4>Security</h4>
                    </div>
                    <div className="text">
                      <p>
                        Re-roofing your home can be a significant investment,
                        but with our experience and expertise, we can help you
                        make the most of your investment.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="service-info-section">
            <div className="service-info-upper-section">
              <h3>20 YEARS OF EXPERTISE</h3>
              <button className="service-btn cta">
                <a className="call-btn-text" href="tel:01617062540">
                  CALL US NOW
                </a>
              </button>
            </div>
            <div className="service-info-lower-section">
              <div className="service-info-top">
                <p>
                  At Roofing and Joinery Northwest Ltd, we offer free,
                  non-obligatory quotes and estimates.
                </p>
              </div>
              <div className="service-info-bottom">
                <p>
                  Our surveys come with a detailed description of our findings,
                  so that all potential clients are fully aware of what work is
                  required.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReRoof;
