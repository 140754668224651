import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination } from "swiper";

import Landing1 from "../images/RJNWlanding1.jpeg";
import Splash1 from "../images/RJNWsplash1.jpeg";
import Splash2 from "../images/RJNWsplash2.jpeg";
import Splash3 from "../images/RJNWsplash3.jpeg";

const Landing = () => {
  return (
    <div id="home">
      <main className="landing-main">
        <div className="landing-header-section">
          <div className="upper-header-container">
            <div className="upper-header-img">
              <div className="upper-header-img-container">
                <img src={Landing1} alt="low shot of a tiled roof" />
              </div>
            </div>
            <div className="upper-header-text">
              <h4>MASTER ROOFERS, 20 YEARS OF EXPERIENCE</h4>
              <div className="upper-text-cover"></div>
            </div>
          </div>
          <div className="lower-header-container">
            <h1>ROOFING & JOINERY NORTHWEST. EXPERTISE BEYOND EXPECTATION</h1>
            <p>
              Roofing and Joinery Northwest Ltd is a leading provider of
              roofing, joinery, facia and soffits services in the North West of
              England, we pride ourselves on offering exceptional projects
              throughout the region.
            </p>
          </div>
        </div>
        <div className="landing-splash-section">
          <div className="splash-carousel-container">
            <div className="splash-carousel-img-container">
              <Swiper
                spaceBetween={30}
                effect={"fade"}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: true,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[EffectFade, Autoplay, Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src={Splash1} alt="roof under construction" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Splash2} alt="workers constructing a roof" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Splash3} alt="roof under construction" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="https://lh3.googleusercontent.com/poktdfyggkN8yslZe66rneqcgvh00ROQp1yU4hOBSW7G5EZrQvgNwWHXlMBGko-R2lO0n96KgXG7P5-dDIlK_GAbFXHYNtMEl-xJtnhIn0K_SOxjDXHnm33KCUaA=w1536-rw-v1-e365" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="splash-cta-container">
            <h4>find out more {">"}</h4>
            <a href="#contact">
              <button className="get-quote-btn cta">CONTACT US</button>
            </a>
          </div>
        </div>
        <div className="landing-reveal-block-left"></div>
        <div className="landing-reveal-block-right"></div>
      </main>
      <div id="about" className="spacer-container">
        <div className="spacer-left"></div>
        <div className="spacer-right"></div>
      </div>
    </div>
  );
};

export default Landing;
